import {FC, useEffect, useState} from "react";
import {Box, IconButton, Paper, Typography} from "@mui/material";
import './styles.scss'
import {TreeList} from "../tree-list";
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

type TProps = {
    el: any,
    deleteCard: (id: string,name: string) => void,
    editCard: (values: any) => void,
}


export const CustomCard: FC<TProps> = ({el,deleteCard,editCard}) => {
    let [fields, setFields] = useState([]);

    const handleDeleting = () => {
        deleteCard(el.id,el.name)
    }

    const handleEdit = () => {
        editCard(el)
    }

    useEffect(() => {
        if (el) {
            // @ts-ignore
            let keys = Object.keys(el).filter(el => el !== 'id')
            setFields((keys as never))
        }
    },[el])

        return (
            <Paper className='card'>
                {fields.map(field => (
                    Array.isArray(el[field]) ?
                    <Box key={field} className='card-field card-field__list'>
                        <Typography className='card-field__title'>Accesses</Typography>
                        {
                            <Box className='list'>
                                <TreeList items={el[field]}/>
                            </Box>

                        }
                    </Box>
                        : <Box key={field} className='card-field '>
                            <Typography className='card-field__title'>{field}:</Typography>
                            <Typography>{ el[field]}</Typography>
                        </Box>
                    ))}

                <Box className='card-buttons' >
                    <IconButton title='Edit' size='small' onClick={handleEdit}>
                        <ModeEditIcon className='edit-icon' />
                    </IconButton>


                    <IconButton title='Delete' size='small' onClick={handleDeleting}>
                        <DeleteIcon className='del-icon' />
                    </IconButton>
                </Box>

            </Paper>
        )
}
