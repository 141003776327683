import axios from "axios";
import {environment} from "../environments/environment";
import { writeAcces} from "./interceptors/reqOperations";
import {IStore} from "../models";
import {handleError} from "./error/handleError";

let store: IStore;

export const injectStore = (_store: IStore ) => {
    store = _store
}

const $api = axios.create({
    baseURL: `${environment.apiUrl}`,
    validateStatus: function (status) {
        return status >= 200 && status < 400
    },
    withCredentials: true,
})



$api.interceptors.request.use((config) => {

    writeAcces(config,store);
    return config;
}, (e) => {
    return Promise.reject(e);
});

$api.interceptors.response.use(null,(error) => {
   return handleError($api,error, store);
});

export default $api;
