import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {FC} from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import {useDispatch} from "react-redux";
import {logoutAction} from "../../store/slices/tokensSlice";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HomeIcon from '@mui/icons-material/Home';
import {HOME, ROLES, USERS} from "../../routes/paths";
import {useClearErrorsNavigate} from "../../hooks/useClearErrorsNavigate";
import './style.scss'
import {uiChangeMessageAction} from "../../store/slices/uiSlice";
type TProps = {
    showMenu: (b: boolean) => void;
}

export const NavigationMenuList:FC<TProps> = ({showMenu}) => {
    let dispatch = useDispatch();
    let navigate = useClearErrorsNavigate();

    let handleLogout = () => {
        dispatch(uiChangeMessageAction({
            isShow: true,
            title: ':(',
            desc: 'Are you sure that you wanna leave?',
            role: 'acc',
            callback: () => dispatch(logoutAction())
        }))
    }

    const handleClick = (route: string) => {
        showMenu(false)
        navigate(route)
    }

    return (
        <Box className='list-wrap'>
            <List
                sx={{ width: '300px', maxWidth: 360}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItem className='list-item'>
                    <ListItemButton onClick={() => handleClick(HOME)}>
                        <ListItemIcon className='item-icon'>
                            <HomeIcon  />
                        </ListItemIcon>
                        <ListItemText>
                            Home
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

                <ListItem className='list-item'>
                    <ListItemButton onClick={() => handleClick(USERS)}>
                        <ListItemIcon className='item-icon'>
                            <SupervisorAccountIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Users
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

                <ListItem className='list-item'>
                    <ListItemButton onClick={() => handleClick(ROLES)}>
                        <ListItemIcon className='item-icon'>
                            <SupervisorAccountIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Roles
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

                {/*<ListItem className='list-item'>*/}
                {/*    <ListItemButton onClick={() => handleClick(CLAIMS)}>*/}
                {/*        <ListItemIcon className='item-icon'>*/}
                {/*            <PermDataSettingIcon />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText>*/}
                {/*            Claims*/}
                {/*        </ListItemText>*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}



                <Divider></Divider>
                <ListItem className='list-item'>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon className='item-icon'>
                            <LogoutIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>
                            Logout
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

            </List>
        </Box>
    )
}
