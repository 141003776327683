import {put, call} from "@redux-saga/core/effects";
import {errorsUiUpdateAction} from "../slices/errorSlice";
import {AxiosResponse} from "axios";
import {IClaimData, IError, } from "../../models";
import {fetchGetRoleClaimsList} from "../../services/RoleService";
import {addClaimsAction} from "../slices/claimsSlice";

export function* getClaimsSaga(): Generator {
    try {
        yield put(errorsUiUpdateAction(null));

        let res: AxiosResponse<IClaimData[]> | IError | unknown = yield call(() => fetchGetRoleClaimsList())
        if ((res as AxiosResponse).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
            throw res;
        }
        else {
            yield  put(addClaimsAction((res as IClaimData[])));
        }

    }
    catch (e) {
        if (!(e as IError).status) {
            yield put(errorsUiUpdateAction((e as IError)));
        }
    }
}
