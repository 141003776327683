import {takeEvery, takeLatest} from 'redux-saga/effects';
import {signInSaga} from "./auth-sagas/signInSaga";
import {updateTokensSaga} from "./token-sagas/updateTokensSaga";
import {LOGOUT, UPDATE_TOKENS, UPDATE_PASS_CONFIRM, WRITE_TOKENS} from "../slices/tokensSlice";
import {confirmPasswordSaga} from "./auth-sagas/confirmPasswordSaga";
import {logoOutSaga} from "./logoOutSaga";
import {SIGN_IN} from "../slices/signInSlice";
import {
  DELETE_USER_ROLE,
  GET_USER_CLAIMS,
  GET_USER_ROLES,
  GET_USERS,
  SEND_NEW_USER,
  SEND_USER_ROLES
} from "../slices/usersSlice";
import {getUsersSaga} from "./users-sagas/getUsersSaga";
import { GET_CLAIMS } from "../slices/claimsSlice";
import {getClaimsSaga} from "./getClaimsSaga";
import {getRolesSaga} from "./roles-sagas/getRolesSaga";
import {DELETE_ROLE, EDIT_ROLE, GET_ROLES, SET_NEW_ROLE} from "../slices/roleSlice";
import {sendNewRoleSaga} from "./roles-sagas/sendNewRoleSaga";
import {deleteRoleSaga} from "./roles-sagas/deleteRoleSaga";
import {editRoleSaga} from "./roles-sagas/editRoleSaga";
import {getUserRolesSaga} from "./users-sagas/getUserRolesSaga";
import {addRoleToUserSaga} from "./users-sagas/addRoleToUserSaga";
import {deleteRoleFromUserSaga} from "./users-sagas/deleteRoleFromUserSaga";
import {getUserClaimsSaga} from "./users-sagas/getUserClaimsSaga";
import {addNewUserSaga} from "./users-sagas/addNewUserSaga";
import {GET_DETAILS} from "../slices/adminSlice";
import {getAdminDetailsSaga} from "./getAdminDetailsSaga";
import {writeTokensSaga} from "./token-sagas/writeTokensSaga";


export function* sagas(){
  yield takeEvery(SIGN_IN,signInSaga);
  yield takeLatest(GET_DETAILS, getAdminDetailsSaga);
  yield takeLatest(UPDATE_TOKENS, updateTokensSaga);
  yield takeLatest(WRITE_TOKENS, writeTokensSaga);
  yield takeLatest(UPDATE_PASS_CONFIRM, confirmPasswordSaga);
  yield takeLatest(LOGOUT, logoOutSaga);
  yield takeLatest(GET_USERS,getUsersSaga);
  yield takeLatest(GET_CLAIMS,getClaimsSaga);
  yield takeLatest(GET_ROLES,getRolesSaga);
  yield takeLatest(SET_NEW_ROLE, sendNewRoleSaga);
  yield takeLatest(DELETE_ROLE, deleteRoleSaga);
  yield takeLatest(EDIT_ROLE, editRoleSaga);
  yield takeLatest(GET_USER_ROLES, getUserRolesSaga);
  yield takeLatest(SEND_USER_ROLES, addRoleToUserSaga);
  yield takeLatest(DELETE_USER_ROLE, deleteRoleFromUserSaga);
  yield takeLatest(GET_USER_CLAIMS, getUserClaimsSaga);
  yield takeLatest(SEND_NEW_USER,addNewUserSaga);
 }
