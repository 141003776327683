import {createAction, createSlice} from "@reduxjs/toolkit";
import {IAction, IClaimData, IStoreState,IClaimsState} from "../../models";

const initialState: IClaimsState= {
    data: [],
};


export const claimsSlice = createSlice({
    name: 'claims',
    initialState,
    reducers: {
        addClaimsAction(state: IClaimsState,action:IAction<IClaimData[]>) {
            // @ts-ignore
            state.data = action.payload;
        },
    }

})

export const claimsReducer = claimsSlice.reducer;

// ACTIONS

export const {
    addClaimsAction
} = claimsSlice.actions;

export const GET_CLAIMS = 'userSlice/getClaimsAction';

export const getClaimsAction = createAction(GET_CLAIMS);



// SELECTOR
export const claimsSelector = (state: IStoreState) => state.claims.data;

