import React from 'react';
import './App.css';
import { RouterProvider } from "react-router-dom";
import {router} from "./routes";
import {CustomModal, ErrorBanner, MessageBanner} from "./components";
import {Box, ThemeProvider,} from "@mui/material";
import {theme} from "./theme";
import {uiChangeIsShowMessageAction, uiMessageSelector} from "./store/slices/uiSlice";
import {useDispatch, useSelector} from "react-redux";

function App() {
    const dispatch = useDispatch();
    const message = useSelector(uiMessageSelector);

    return (
        <ThemeProvider theme={theme}>
            <Box component='main' className='App'>
                <CustomModal
                    handleShow={() => dispatch(uiChangeIsShowMessageAction(!message.isShow))}
                    open={message.isShow}
                    width={'360px'}
                    children={<MessageBanner />}
                />
                <ErrorBanner></ErrorBanner>
                <RouterProvider  router={router}/>
            </Box>
        </ThemeProvider>


    );
}

export default App;
