import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {ISignInResponse, IAuthBody, IError, ILetterResponse, IUpdatePasswordBody, IUserDetailsBody} from "../models/";
import $api from "../http";
import {transformError} from "../helpers";
import {checkResponse} from "./checkResponse";

const setPasswordPath: string = `/user/password/set`;
const signInPath: string = '/auth/signin';
const signUpPath: string = 'SignUp';
const confirmEmailPath = `/User/email/confirm`;
const resendLatterPath = `/User/email/confirm/resend`;
const signOutPath = '/auth/signout';
const userDetailsPath: string = `/user/details`;
// const userTypesPath: string = `/Utilities/user/type`;


export const fetchSetPassword = (data: IUpdatePasswordBody) => {
    return $api.put(`${setPasswordPath}`, data).then(res => checkResponse(res)).catch((e: AxiosError) => transformError(e))
};

export const fetchSignIn = async (data: IAuthBody): Promise<AxiosResponse<any> | IError> => {
    return $api.post<ISignInResponse>(signInPath,{
        email: data.email,
        password: data.password,
        rememberMe: data.rememberMe,
        accessType: data.accessType,
    }).then(res => checkResponse(res)).catch((e: AxiosError) =>  transformError(e))
};


export const fetchSignUp = async (data: IAuthBody) => {
    return $api.post(signUpPath,{email: data.email, password: data.password})
        .then(res =>  checkResponse(res))
        .catch((e: AxiosError) =>  transformError(e))
};


export const fetchConfirmEmail= async (data: string) => {
    return $api.post<AxiosRequestConfig>(confirmEmailPath,{key: data})
        .then(res =>  checkResponse(res))
        .catch((e: AxiosError) =>  transformError(e));
};

export const fetchConfirmLatter = async (data?: any): Promise<AxiosResponse<ILetterResponse> | IError > => {
    return $api.post(`${resendLatterPath}`)
        .then(res =>  checkResponse(res))
        .catch((e: AxiosError) =>  transformError(e))
};
export const fetchSignOut = async (): Promise<AxiosResponse<ILetterResponse> | IError > => {
    return $api.get(signOutPath)
        .then(res =>  checkResponse(res))
        .catch((e: AxiosError) =>  transformError(e))
};

export const fetchAddUserDetails = async (data: IUserDetailsBody): Promise<AxiosResponse<any> | IError> => {
    return  $api.post(userDetailsPath,data)
        .then(res =>  checkResponse(res))
        .catch((e: AxiosError) =>  transformError(e))
};

export const fetchGetUserDetails = async (): Promise<AxiosResponse<IUserDetailsBody> | IError> => {
    return  $api.get(userDetailsPath)
        .then(res =>  checkResponse(res))
        .catch((e: AxiosError) =>  transformError(e))
};
