import {createTheme} from "@mui/material";

export const theme = createTheme({
    components: {
        MuiTextField: {

            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-root": {
                        color: "#BEB7B7",
                        borderColor: '#000',
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#2D394B",
                            borderWidth: "2px",
                        },
                        "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#F7B03E",
                                borderWidth: "3px",
                            },
                        },
                        "&:hover:not(.Mui-focused)": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#F7B03E",
                            },
                        },
                    },
                    "& .MuiInputLabel-outlined": {
                        color: "#BEB7B7",
                        fontWeight: "bold",
                        "&.Mui-focused": {
                            color: "#F7B03E",
                        },
                    },
                    // Filled
                    // "& .MuiFilledInput-root": {
                    //     color: "#000",
                    //     fontFamily: "Arial",
                    //     fontWeight: "bold",
                    //     backgroundColor: "#e7e7e7",
                    //     borderTopLeftRadius: "7px",
                    //     borderTopRightRadius: "7px",
                    //     "&:before": {
                    //         borderColor: "#2e2e2e",
                    //         borderWidth: "2px",
                    //     },
                    //     "&:after": {
                    //         borderColor: "secondary.main",
                    //         borderWidth: "3px",
                    //     },
                    //     ":hover:not(.Mui-focused)": {
                    //         "&:before": {
                    //             borderColor: "#e7e7e7",
                    //             borderWidth: "2px",
                    //         },
                    //         backgroundColor: "#f4f4f4",
                    //     },
                    // },
                    // "& .MuiInputLabel-filled": {
                    //     color: "#2e2e2e",
                    //     fontWeight: "bold",
                    //     "&.Mui-focused": {
                    //         color: "secondary.main",
                    //     },
                    // },
                    // // Standard
                    // "& .MuiInput-root": {
                    //     color: "#000",
                    //     fontFamily: "Arial",
                    //     fontWeight: "bold",
                    //     "&:before": {
                    //         borderColor: "#2e2e2e",
                    //         borderWidth: "2px",
                    //     },
                    //     "&:after": {
                    //         borderColor: "secondary.main",
                    //         borderWidth: "3px",
                    //     },
                    //     ":hover:not(.Mui-focused)": {
                    //         "&:before": {
                    //             borderColor: "#e7e7e7",
                    //             borderWidth: "2px",
                    //         },
                    //     },
                    // },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    "& .MuiInputLabel-outlined": {
                        color: "#BEB7B7",
                        fontWeight: "bold",
                        "&.Mui-focused": {
                            color: "#F7B03E",
                        },
                    },
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    "& .MuiSelect-select": {
                        color: "#BEB7B7",
                        borderColor: '#000',
                        fontFamily: "Arial",
                        fontWeight: "bold",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2D394B",
                        borderWidth: "2px",
                    },

                    "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#F7B03E",
                            borderWidth: "3px",
                        },
                    },
                    "&:hover:not(.Mui-focused)": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#F7B03E",
                        },
                    },

                }
            }
        },
        // MuiCheckbox: {
        //     styleOverrides: {
        //         root: {
        //
        //
        //         }
        //     }
        // }
    },
});

// MuiSelect-select  MuiInputBase-input MuiOutlinedInput-input css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
