import {IUserState} from "../../models";
import {FC, useCallback, useEffect, useState} from "react";
import {
    Box,
    FormControl,
    IconButton,
    Input,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss';
import {useNavigate} from "react-router-dom";
import {USER} from "../../routes/paths";
import {useDispatch} from "react-redux";
import {addUserAction} from "../../store/slices/usersSlice";

type TProps = {
    users: IUserState[];
}

const columns = [

    { id: 'phone', label: 'Phone number',align: 'left', minWidth: 100,children: null },
    { id: 'name', label: 'Name',align: 'left', minWidth: 100,children: null },
    { id: 'surname', label: 'Surname', minWidth: 100, children: null },
];



const AccessFilter: FC<{sendType: (item:string) => void, type: string}> = ({sendType,type}) => {
    let [value,setValue] = useState(type);
    const handleChoice = (e:any) => {
        setValue(e.target.value);
        sendType(e.target.value);
    };

    useEffect(() => {
        setValue(type)
    },[type])
    return (
        <FormControl  variant='standard'>
            <Select
                id="userAccess"
                name='userAccess'
                value={value}
                label="Access type"
                className='access-select'
                onChange={handleChoice}
                onSelect={handleChoice}
            >
                <MenuItem  value={'All'}>All</MenuItem>
                <MenuItem  value={'Admin'}>Admin</MenuItem>
                <MenuItem  value={'Client'}>Client</MenuItem>
                <MenuItem  value={'Merchant'}>Merchant</MenuItem>

            </Select>
        </FormControl>

    )
};
const SearchFilter: FC<{sendSearch: (item:string) => void}> = ({sendSearch}) => {
    let [value,setValue] = useState('');
    const handleSearch = (e:any) => {
        setValue(e.target.value);
        sendSearch(e.target.value);
    };
    return (
        <Input
            autoFocus={true}
            sx={{border: 0, outline: 0, backgroundColor: '#ffff', width: '100px'}}
            value={value}
            onChange={handleSearch}
        />

    )
};


export const UsersTable: FC<TProps> = ({users}) => {
    const [usersList, setUsersList] = useState(users);
    const [filteredList, setFilteredList] = useState(usersList);
    const [filAccess, setFilAccess] = useState('All');
    const [email, setEmail] = useState('');
    const [isSearch,setIsSearch] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleRedirect = useCallback((user: IUserState) => {
        dispatch(addUserAction(user))
        let path = USER.replace(':id',(user.id as string));
        navigate(path)
    },[dispatch,navigate]);

    const handleSearch = () => {
        setEmail('');
        setIsSearch(!isSearch);
    }

    useEffect(() => {
        setUsersList(users);
        return;
    },[users]);


    useEffect(() => {
        if (filAccess === 'All') {
            setFilteredList(usersList)
        }
        else {
            setFilteredList(usersList.filter(el => el.userAccess === filAccess))
        }
        return;
    },[filAccess,usersList])

    useEffect(() => {
        if (email === '') {
            setFilteredList(usersList)
        }
        else {
            setFilAccess('All')
            setFilteredList(usersList.filter(el => el.email.includes(email)))
        }
        return;
    },[email,usersList]);

    return (
        <Box className='table-wrap'>
            <TableContainer className='table'>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >

                        <TableRow className='table-header__row'>
                            <TableCell
                                className='table-header__cell'
                                align='left'
                                style={{width: '150px'}}
                            >
                                <Box sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                    Email
                                    {isSearch ? <SearchFilter sendSearch={setEmail} /> : null}
                                    <IconButton onClick={handleSearch}>
                                        {isSearch ? <CloseIcon className='table-icon' /> : <SearchIcon className='table-icon' />}
                                    </IconButton>
                                </Box>
                            </TableCell>

                            {columns.map(el => (
                                <TableCell
                                    key={el.id}
                                    className='table-header__cell'
                                    align={(el.align as 'left')}
                                    style={{minWidth: el.minWidth,}}
                                >
                                    {el.label}
                                </TableCell>
                            ))}

                            <TableCell
                                align='left'
                                className='table-header__cell'
                                style={{
                                    width: '150px',
                                }}
                            >
                                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                    Access Type
                                    <AccessFilter type={filAccess} sendType={setFilAccess} />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='table-body'>
                        {filteredList.map((user) => (
                            <TableRow className='table-row' onClick={() => handleRedirect(user)} key={user.id}>
                                <TableCell className={'table-cell'} align="left">{user.email}</TableCell>
                                <TableCell className={'table-cell'}>{user.phoneNumber ? user.phoneNumber : 'Empty'}</TableCell>
                                <TableCell className={'table-cell'}>{user.details ? user.details.name : 'Empty'}</TableCell>
                                <TableCell className={'table-cell'}>{user.details ? user.details.surname : 'Empty'}</TableCell>
                                <TableCell className={'table-cell'}>
                                    {user.userAccess}

                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

            <Typography sx={{paddingRight: '15px'}}>Number of users: {filteredList.length}</Typography>
        </Box>

    );
}
