import * as Yup from "yup";


class ValidationSchema {
    private emailRegExp: RegExp = new RegExp(`^(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])$`);
    private specCharReg = new RegExp(`(?=.*[!@#$%^&])`,'g');
    private numberReg = new RegExp(`(?=.*[0-9])`,'gi');
    private smallLetterReg = new RegExp(`(?=.*[a-zа-яёїієґ])`,'g');
    private bigLetterReg = new RegExp(`(?=.*[A-ZА-ЯЁЇІЄҐ])`,'g');
    private textReg = new RegExp(`^[A-Za-zА-Яа-яёЁЇїІіЄєҐґ]{2,36}$`,'g');
    private passReg = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]*).{8,}$','g');
    private phoneReg = new RegExp('^\\+?3?8?(0\\d{9})$','g')

    public  signIn(): Yup.Schema   {
        return  Yup.object({
            email: Yup.string()
                .strict(true)
                .min(5,'Email is too short')
                .max(65, 'Email is to long')
                .matches(this.emailRegExp, 'Incorrect format of email')
                .required('Email address is required'),
            password: Yup.string()
                .strict(true)
                .min(8,'Password is too short')
                .max(64,'Password is too long')
                .matches(this.passReg,'Incorrect format of password')
                .required('Password is required'),
            rememberMe: Yup.boolean()
        })
    }
    public  confirmEmail(): Yup.Schema   {
        return  Yup.object({
            key: Yup.string()
                .strict(true)
                .min(7,'Key is too short')
                .max(7, 'Key is to long')
                .required('Key is required'),
        })
    }
    public email(): Yup.Schema {
      return Yup.object({
          email: Yup.string()
              .strict(true)
              .min(5,'Email is too short')
              .max(65, 'Email is to long')
              .matches(this.emailRegExp, 'Incorrect format of email')
              .required('Email address is required')
          ,
      })
    }
    public password(): Yup.Schema {
        return Yup.object({
            password: Yup.string()
                .strict(true)
                .min(8,'Password is too short')
                .max(64,'Password is too long')
                .matches(this.passReg,'Incorrect format of password')
                .required('Password is required'),
        })
    }
    public setPassword(): Yup.Schema {
        return Yup.object({

            password: Yup.string()
                .strict(true)
                .min(8,'Password is too short')
                .max(64,'Password is too long')
                .matches(this.specCharReg,'should contain spec char')
                .matches(this.numberReg,'should contain at least one digit')
                .matches(this.bigLetterReg,'should contain upper case letters')
                .matches(this.smallLetterReg,'should contain lower case letters')
                .required('Password is required'),

            repPassword: Yup.string()
                .strict(true)
                .min(8,'Copy is too short')
                .max(64,'Copy is too long')
                .oneOf([Yup.ref('password')],'Not confirmed')
                .required('Repeat password is required'),
        })
    }
    public AddRole(): Yup.Schema {
        return Yup.object({
            name: Yup.string()
                .strict(true)
                .min(2,'Name is too short')
                .max(64,'Name is too long')
                .matches(this.textReg,'Only letters')
                .required('Name is required'),

        })
    }


    public addUser(): Yup.Schema {
        return Yup.object({
            email: Yup.string()
                .strict(true)
                .min(5,'Email is too short')
                .max(65, 'Email is to long')
                .matches(this.emailRegExp, 'Incorrect format of email')
                .required('Email address is required')
            ,
            phoneNumber: Yup.string()
                .strict(true)
                .min(13,'Incorrect length of phone number')
                .max(13, 'Incorrect length of phone number')
                .matches(this.phoneReg, 'Incorrect format of phone number')
            ,
            name: Yup.string()
                .strict(true)
                .min(2,'Name is too short')
                .max(64,'Name is too long')
                .matches(this.textReg,'Only letters')
                .required('Name is required'),

            surname: Yup.string()
                .strict(true)
                .min(2,'Surname is too short')
                .max(36, 'Surname is to long')
                .matches(this.textReg,'Please use only ukraininan or english letters')
                .required('Surname is required'),
        })
    }
}

export const validation = new ValidationSchema()
