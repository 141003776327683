import {
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {FC, useEffect, useState} from "react";
import {DeepRow} from "../deep-row";
import {IClaimData, IRoleData} from "../../models";





type TProps  = {
    data: any,
    arrField? : 'claims' | 'childClaims'
}

export const RoleTable: FC<TProps> = ({data,arrField}) => {
    let [list, setList] = useState(data);
    let [headers, setHeaders] = useState<string[]>([])
    useEffect(() => {
        if ((data as Array<any>).length > 0) {
            setHeaders(Object.keys(data[0]))
            setList(data)
        }

    },[data])

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            {headers.map(el => <TableCell
                                style={{
                                    backgroundColor: '#333333',
                                    color: '#ffff',
                                    textTransform: 'uppercase'
                                }}
                                key={el}
                                align="left"
                            >
                                {el}
                            </TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            list.map((item: IClaimData & IRoleData) => (
                                <DeepRow arrField={arrField ? arrField : 'childClaims'} headers={headers} key={item.id} row={item} />))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

