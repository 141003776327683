import {IStore} from "../../models";
import {AxiosError} from "axios";
import {
    logoutAction,
    tokensRefreshSelector,
    writeTokensAction
} from "../../store/slices/tokensSlice";
import {fetchRefreshToken} from "../../services/RefreshService";


export const handleError = async (axios: any,error: AxiosError,store: IStore) => {

    if (error && error.response) {

        if (error.response.status === 401) {
            store.dispatch(logoutAction());
            return Promise.reject(error);

        }
        // @ts-ignore
        else if (error.response.data && error.response.data.errorCode === 'RefreshRequired') {

            let refToken = tokensRefreshSelector(store.getState());
            let  resp = await fetchRefreshToken(refToken);

            if (!(resp as object).hasOwnProperty('errorCode')) {
                console.log(resp)
                store.dispatch(writeTokensAction((resp as any)));
                return await axios.request(error.config);
            }
            else {
                return Promise.reject(error);
            }

        }
        else {
            return Promise.reject(error)
        }
    }
}
