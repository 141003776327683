import {FC, useState} from "react";
import {Box,Drawer, IconButton, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
import './style.scss'
import { adminSelector} from "../../store/slices/adminSlice";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {CustomContainer} from "../customContainer";
import {IUserDetails} from "../../models";
import Logo from '../../assets/images/logo_reflum.png';
import {NavigationMenuList} from "../nav-menu-list";


export const Header: FC = () => {
    let [showMenu, setShowMenu] = useState(false);
    let user = useSelector(adminSelector);

    const toggleList = (newOpen: boolean) => () => {
        console.log(user.details)
        setShowMenu(newOpen);
    };

    return (
        <Box className='header' component={"header"}>
            <CustomContainer>

                <Box className='header__inner'>
                    <IconButton className='header__menu-btn' onClick={toggleList(true)}>

                        <MenuIcon className='header__menu-icon'></MenuIcon>
                    </IconButton>
                    <Drawer  open={showMenu} onClose={toggleList(false)}>
                        {<NavigationMenuList showMenu={setShowMenu} />}
                    </Drawer>

                    <Box className='header__user-details'>
                        <AccountCircleIcon fontSize={"large"} />
                        <Typography>
                            {(user.details as IUserDetails).name ? (user.details as IUserDetails).name : user.email}
                        </Typography>
                    </Box>

                    <img width='100px' src={Logo} alt="logo"/>
                </Box>
            </CustomContainer>
        </Box>
    )
}
export default Header;
