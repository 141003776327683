import {FC, ReactElement, useEffect, useState} from "react";
import {Modal, Paper} from "@mui/material";
import './styles.scss';

type TProps = {
    children: ReactElement,
    width: string,
    handleShow: () => void
    open: boolean
}

export const CustomModal: FC<TProps> = ({handleShow,children,width,open}) => {
    let [isShow, setIsShow] = useState(open);

    useEffect(() => {
        setIsShow(open)
        return;
    },[open])

    return (
        <Modal
            open={isShow}
            onClose={handleShow}
            className='modal'
        >
            <Paper             sx={{maxWidth: width}}
                               className='modal-container'>
                {children}
            </Paper>
        </Modal>
    )
}
