import {FC, useCallback, useEffect, useState} from "react";
import {Avatar, Box, Chip, Paper, Typography} from "@mui/material";
import {IClaimData, IRoleData, IUserDetails, IUserState} from "../../models";
import './style.scss'
import {CustomMenu} from "../menu";
import {TreeList} from "../tree-list";
import {deletedUserRolesAction} from "../../store/slices/usersSlice";
import {uiChangeMessageAction} from "../../store/slices/uiSlice";
import {useDispatch} from "react-redux";


type TProps = {
    user: IUserState,
    userRoles: IRoleData[] | null,
    userClaims: IClaimData[] | null,
    addRoleHandle: () => void,
}

export const UserCard:FC<TProps> = ({user,userRoles,addRoleHandle,userClaims}) => {
    let [data, setData] = useState(user);
    let [roles, setRoles] = useState(userRoles);
    let [claims, setClaims] = useState(userClaims);
    const dispatch = useDispatch();

    const deleteRole = useCallback((el: IRoleData) => {
        dispatch(uiChangeMessageAction({
            isShow: true,
            title: 'Delete!',
            desc: `Are sure that you want delete role \n "${el.name} from user ${user.email}"?`,
            role: 'del',
            callback: () => dispatch(deletedUserRolesAction({id: el.id}))
        }
        ))
    },[user,dispatch])





    useEffect(() => {
        setData(data);
        setRoles(userRoles);
        setClaims(userClaims)
    },[user,userRoles,claims,data,userClaims]);

    return (
        <Paper className='user-wrap'>
            <Box className='user-inner'>
                <Box>
                    <Avatar className='avatar'></Avatar>
                </Box>
                <Box className='user-info'>
                    <Box className='user-info__field'>
                        <Typography className='user-info__title'>ID:</Typography>
                        <Typography>{data?.id}</Typography>
                    </Box>

                    <Box className='user-info__field'>
                        <Typography className='user-info__title'>Email:</Typography>
                        <Typography>{data?.email}</Typography>
                    </Box>

                    <Box className='user-info__field'>
                        <Typography className='user-info__title'>Access type:</Typography>
                        <Typography>{data?.userAccess}</Typography>
                    </Box>

                    <Box className='user-info__field'>
                        <Typography className='user-info__title'>Name:</Typography>
                        <Typography>{data.details && (data?.details as IUserDetails).name ? (data?.details as IUserDetails).name : 'Empty'}</Typography>
                    </Box>

                    <Box className='user-info__field'>
                        <Typography className='user-info__title'>Surname:</Typography>
                        <Typography>{data.details && (data?.details as IUserDetails).surname ? (data?.details as IUserDetails).surname : 'Empty'}</Typography>
                    </Box>

                    <Box className='user-info__field'>
                        <Typography className='user-info__title'>Roles:</Typography>
                        <Box className='user-roles'>{
                            roles && roles.length > 0 ? (roles as IRoleData[]).map((el: any) =>
                                    <Chip
                                        className='card-chip'
                                        key={el.id}
                                        label={el.name}
                                        onDelete={() => deleteRole(el)} />)
                                : 'Empty'
                        }</Box>
                    </Box>

                    <Box className='user-info__field'>
                        <Typography className='user-info__title'>Claims:</Typography>
                        {claims && claims.length > 0 ? <TreeList items={(userClaims as unknown as IClaimData[])} /> : <Typography>Empty</Typography>}
                    </Box>
                </Box>


            </Box>
            <Box sx={{position: 'absolute', right: '5px', top: '5px'}}>
                <CustomMenu iconClassName='settings-icon' items={[{label: 'Add new role', callback: addRoleHandle}]} />
            </Box>
        </Paper>

    )
}
