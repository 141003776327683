import { configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import {sagas} from "./sagas";
import {
    tokensReducer,
    uiReducer,
    signInReducer,
    errorsReducer, adminReducer, usersReducer, claimsReducer, rolesReducer,
} from "./slices";
import storage from 'redux-persist/lib/storage'
import {router} from "../routes";
import {
    persistReducer,
    persistStore,
    } from "redux-persist";




const persistConfigTokens = {
    key: 'tkns',
    storage,
};
const persistConfigAdmin = {
    key: 'adminData',
    storage,
};



const persistedTokens = persistReducer(persistConfigTokens,tokensReducer);
const persistedAdmin = persistReducer(persistConfigAdmin,adminReducer);

export const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        tokens: persistedTokens,
        admin: persistedAdmin,
        signIn: signInReducer,
        ui: uiReducer,
        errors: errorsReducer,
        users: usersReducer,
        claims: claimsReducer,
        roles: rolesReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: false,
            serializableCheck: false
        }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
})

// For routes from sagas
sagaMiddleware.setContext({
    router
});

//Activate sagas
sagaMiddleware.run(sagas)

// Band store with localStorage
const persistor = persistStore(store)

export {
    store,
    persistor,
}
