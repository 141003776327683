import {SimpleTreeView, TreeItem} from "@mui/x-tree-view";
import {FC, useEffect, useState} from "react";

type TProps = {
    items: any[]
}


export const TreeList: FC<TProps> = ({items}) => {
    let [itemsList, setItemsList] = useState(items)

    useEffect(() => {
        setItemsList(items)
    },[items])

    return (
        <SimpleTreeView>
            {itemsList.map((item,index) => (
                <TreeItem  key={item.id} itemId={`${item.id + index}`} label={item.name}>
                    {Array.isArray(item.childClaims) && item.childClaims.length > 0 ? <TreeList items={item.childClaims} /> : null}
                </TreeItem>
            ))}
        </SimpleTreeView>
    )
}
