import { IUserClaims} from "../../../models";
import {jwtDecode} from "jwt-decode";
import {put} from "@redux-saga/core/effects";
import {addAdminEmailAction, addAdminIdAction, changeAdminTypeAction} from "../../slices/adminSlice";
import {
    addExpireAction,
    addlogTimeAction,
    updateEmailConfirmAction,
    updateResetConfirmAction
} from "../../slices/tokensSlice";

export function* decodeJwtSaga(jwt:string) {

    try {
        let userClaims: IUserClaims | unknown = yield jwtDecode(jwt,{header: true});

        if ((userClaims as IUserClaims).access_type === 'Admin') {
            //USER DATA FROM CLAIMS
            yield put(addAdminEmailAction((userClaims as IUserClaims).unique_name));
            yield put(addAdminIdAction((userClaims as IUserClaims).nameid));

            // TOKENS DATA FROM CLAIMS
            yield put(updateEmailConfirmAction((userClaims  as IUserClaims).email_confirmed))
            yield put(updateResetConfirmAction((userClaims  as IUserClaims).password_reset_required))
            yield put(changeAdminTypeAction((userClaims as IUserClaims).access_type))
            yield put(addExpireAction((userClaims as IUserClaims).exp));
            yield put(addlogTimeAction((userClaims as IUserClaims).nbf));
            return userClaims;
        }

        else {
            return  {
                status:  'Rights',
                errorCode: -1,
                messages: {Email: ["Sorry but you haven't enough rights for access"]}
            }
        }


    }
    catch (e) {
        // yield put(errorsSignInUpdateAction((e as IError)))
    }
}
