import React, {FC,Suspense} from "react";

import Header from "../header";
import {Box, CircularProgress, Typography} from "@mui/material";
import Copyright from "../copyright";
import {ErrorBoundary} from "react-error-boundary";
import {CustomContainer} from "../customContainer";

type TProps = {
    header?: boolean,
    footer?: boolean,
    children: any,
}

export const Layout: FC<TProps> = ({header, footer,children}) => {

    return (
        <Box>
            {
                header ?  <Header /> : null
            }
            <CustomContainer component='main'>
                <Suspense
                    fallback={
                    <Box sx={{width:' 100%'}}>
                        <CircularProgress sx={{margin: '180px auto'}} size={50}  color="warning" />
                    </Box>
                }>
                    <ErrorBoundary fallback={<Typography variant='h5' sx={{mt:15, color: '#BEB7B7'}}>Ooops! something went wrong try to reload page</Typography>}>
                        {children}
                    </ErrorBoundary>
                </Suspense>
            </CustomContainer>

            {
                footer ? <Copyright /> : null
            }
        </Box>
    )
}
