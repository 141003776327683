import {IAction, IError, IRoleData} from "../../../models";
import {select,call, put} from "@redux-saga/core/effects";
import {uiChangeLoadingAction, uiChangeMessageAction} from "../../slices/uiSlice";
import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {AxiosResponse} from "axios";
import {fetchDeleteRole} from "../../../services/RoleService";
import {roleDelSelector} from "../../slices/roleSlice";
import {getRolesSaga} from "./getRolesSaga";

export function* deleteRoleSaga(action: IAction<string>):Generator {
    try {
        yield put(uiChangeLoadingAction(true));
        yield put(errorsUiUpdateAction(null));
        let role = yield select(roleDelSelector);

        let res: AxiosResponse<IRoleData[]> | IError | unknown = yield call(() => fetchDeleteRole((role as IRoleData).id as string))
        if ((res as AxiosResponse).hasOwnProperty('errorCode')  && (res as IError).errorCode !== 10 ) {
            throw res;
        }
        else {
            yield put(uiChangeMessageAction({
                isShow: true,
                title: 'Deleted',
                desc: `Role ${(role as IRoleData).name} was deleted`,
                role: 'inf',
                callback: undefined,
            }))
            yield call(() => getRolesSaga())
        };

    }
    catch (e) {
        if (!(e as IError).status) {
            yield put(errorsUiUpdateAction((e as IError)));
        }
    }
    finally {
        yield put(uiChangeLoadingAction(false));
    }
}
