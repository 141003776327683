import {createAction, createSlice} from "@reduxjs/toolkit";
import {IAction, IStoreState, IUserDetails, IUserState} from "../../models";
import {TUserType} from "../../models/UserClaims";

const initialState: IUserState= {
    id: '',
    email: '',
    userAccess: 'Client',
    details: {
        name: '',
        surname: '',
        sex: '',
        birthday: null,
    }
};


export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        addAdminEmailAction(state: IUserState,action:IAction<string>) {
            state.email = action.payload;
        },
        changeAdminTypeAction(state: IUserState,action:IAction<TUserType>) {
            state.userAccess = action.payload;
        },
        addAdminIdAction(state:IUserState,action:IAction<string>) {
            state.id = action.payload;
        },
        addAdminDetailsAction(state:IUserState,action:IAction<IUserDetails>) {
            state.details = action.payload;
        },
        addAdminDetailsNameAction(state:IUserState,action:IAction<string>) {
            (state.details as IUserDetails).name = action.payload;
        },
        addAdminDetailsSurnameAction(state:IUserState,action:IAction<string>) {
            (state.details as IUserDetails).surname = action.payload;
        },
        logOutAdminAction(state:IUserState) {
            state.id = '';
            state.email = '';
            state.userAccess = 'Client';
            state.details = {
                    name: '',
                    surname: '',
                    sex: '',
                    birthday: null,
            }
        }

    }

})

export const adminReducer = adminSlice.reducer;

// ACTIONS

export const {
    addAdminEmailAction,
    addAdminIdAction,
    logOutAdminAction,
    addAdminDetailsAction,
    addAdminDetailsNameAction,
    addAdminDetailsSurnameAction,
    changeAdminTypeAction,
} = adminSlice.actions;

export const UPDATE_DETAILS= 'userSlice/updateAdminDetailsAction';
export const GET_DETAILS = 'userSlice/getAdminDetailsAction';


export const updateAdminDetailsAction = createAction(UPDATE_DETAILS);
export const getAdminDetailsAction = createAction(GET_DETAILS);



// SELECTOR
export const adminSelector = (state: IStoreState) => state.admin;
export const adminEmailSelector = (state: IStoreState) => state.admin.email;
export const adminDetailsSelector = (state: IStoreState) => state.admin.details;
export const adminTypeSelector = (state: IStoreState) => state.admin.userAccess;

