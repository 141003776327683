
export const SIGN_IN = '/signIn';
export const CONFIRM = '/confirm';
export const REDIRECT = '/redirect';
export const USERS = '/users';
export const ROLES = '/roles';
export const CLAIMS = '/claims';
export const USER = '/user/:id';
export const HOME = '/';
export const FLOOD = '*';

