import {
    CLAIMS, CONFIRM,
    FLOOD,
    HOME, ROLES,
    SIGN_IN, USER, USERS,

}
    from "./paths";
import {Layout} from "../components/layout";
import {lazy} from "react";
import {RouteGuardBydPass} from "./guardianRoute";


const HomePage = lazy(() => import('../pages/home-page'));
const SignInPage = lazy(() => import('../pages/sign_in-page'));
const UsersPage = lazy(() => import('../pages/users-page'));
const ClaimsPage = lazy(() => import('../pages/claims-page'));
const RolesPage = lazy(() => import('../pages/roles-page'));
const NotFoundPage = lazy(() => import('../pages/not_found-page'));
const UserPage = lazy(() => import('../pages/user-page'));
const ConfirmPassPage = lazy(() => import('../pages/confirm-pass-page'))

export const auth_routes =  [

    {
        path: SIGN_IN,
        element: <Layout children={<SignInPage />}/> ,
    },
    {
        element: <RouteGuardBydPass goTo={SIGN_IN} />,
        children: [
            {
                path: CONFIRM,
                element: <Layout children={<ConfirmPassPage />} />
            }
        ]
    }

];


export const main_routes = [
    {
        path: HOME,
        element: <Layout header={true} children={<HomePage />}/> ,
    },
    {
        path: USERS,
        element: <Layout header={true} children={<UsersPage />}/> ,
    },
    {
      path: USER,
      element: <Layout header={true} children={<UserPage />}/> ,
    },
    {
        path: CLAIMS,
        element: <Layout header={true} children={<ClaimsPage />}/> ,
    },
    {
        path: ROLES,
        element: <Layout header={true} children={<RolesPage />}/> ,
    },
    {
        path: FLOOD,
        element: <Layout header={true} children={<NotFoundPage />}/> ,
    },
];
