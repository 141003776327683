import {IAction, IError, IUserState} from "../../../models";
import {call, put, select} from "@redux-saga/core/effects";
import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {fetchDeleteRole} from "../../../services/UsersService";
import {getUserClaimsAction, getUserRolesAction, userSelector} from "../../slices/usersSlice";
import {uiChangeMessageAction} from "../../slices/uiSlice";

export function* deleteRoleFromUserSaga (action: IAction<{id: string}>):Generator {
    try {
        yield put(errorsUiUpdateAction(null))
        let user = yield select(userSelector)
        let res = yield call(() => fetchDeleteRole({userId: ((user as IUserState).id as string),roleId: action.payload.id}));

        if ((res as Object).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
           throw res;
        }

        else {
            yield put(getUserRolesAction((user as IUserState).id));
            yield put(getUserClaimsAction((user as IUserState).id));
            yield put(uiChangeMessageAction({
                isShow: true,
                title: 'Deleted!',
                desc: `Role was deleted!`,
                role: 'inf',
                callback: undefined,
            }))
        }
    }
    catch (e) {
        yield put(errorsUiUpdateAction(e as IError))

    }
}
