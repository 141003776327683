import {FC} from "react";
import {Navigate,} from "react-router-dom";
import {useSelector} from "react-redux";
import {CONFIRM, HOME} from "./paths";
import {tokensResetReqSelector} from "../store/slices/tokensSlice";



export const RedirectRoute: FC= () => {
    let emailConf = useSelector(tokensResetReqSelector);
    let path = CONFIRM;

    if(!emailConf) {
        path = HOME;
    }

    return <Navigate to={path} />
};
