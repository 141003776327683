import {call, put} from "@redux-saga/core/effects";

import {AxiosResponse} from "axios";
import {IError, IUserDetails} from "../../models";
import {addAdminDetailsNameAction, addAdminDetailsSurnameAction} from "../slices/adminSlice";
import {errorsUiUpdateAction} from "../slices/errorSlice";
import {uiChangeLoadingAction} from "../slices/uiSlice";
import {fetchGetUserDetails} from "../../services/AuthService";

export function* getAdminDetailsSaga (): Generator {

    try {
        yield put(uiChangeLoadingAction(true));
        yield put(errorsUiUpdateAction(null))
        let resp: unknown | AxiosResponse<IUserDetails> = yield call(() => fetchGetUserDetails());

        if ((resp as Object).hasOwnProperty('errorCode') && (resp as IError).errorCode !== 10) {
            throw resp;
        }
        else {
            yield put(addAdminDetailsNameAction((resp as IUserDetails).name))
            yield put(addAdminDetailsSurnameAction((resp as IUserDetails).surname))
        }
    }
    catch (e) {
        if (!(e as IError).status) {
            yield put(errorsUiUpdateAction((e as IError)));
        }
    }
    finally {
        yield put(uiChangeLoadingAction(false));
    }

}
