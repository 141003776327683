import {call, put} from "@redux-saga/core/effects";
import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {IAction, IClaimData, IError, IRoleData} from "../../../models";
import {fetchGetUserClaims} from "../../../services/UsersService";
import {addUserClaimsAction} from "../../slices/usersSlice";
import {uiChangeLoadingAction} from "../../slices/uiSlice";

export function* getUserClaimsSaga(action: IAction<string>):Generator {
    try {
        yield put(errorsUiUpdateAction(null));
        yield put(uiChangeLoadingAction(true));
        let res: unknown | IRoleData[] | IError = yield call(() => fetchGetUserClaims(action.payload))

        if ((res as Object).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
            throw res;
        }
        else {
            yield put(addUserClaimsAction((res as IClaimData[])))
        }
    }
    catch (e) {
        if (!(e as IError).status) {
            yield put(errorsUiUpdateAction((e as IError)));
        }
    }
    finally {
        yield put(uiChangeLoadingAction(false));

    }
}
