import {useFormikRef} from "../../../hooks";
import {validation} from "../../../schemas/validation.schemas";
import {
    Box, Button,
    Divider,
    FormHelperText,
    TextField,
    Typography
} from "@mui/material";
import {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addNewUserAction, userSelector} from "../../../store/slices/usersSlice";
import {errorsCreateUserSelector} from "../../../store/slices/errorSlice";
import '../styles.scss'

export const UserForm: FC<{setShow : (show: boolean) => void}> = ({setShow}) => {
    const user = useSelector(userSelector);
    const error = useSelector(errorsCreateUserSelector);
    const dispatch = useDispatch();


    const onSubmit = (values: any) => {
        dispatch(addNewUserAction(
            {
                email: values.email,
                phoneNumber: values.phoneNumber ? values.phoneNumber : null,
                details: {
                    name: values.name,
                    surname: values.surname
                }
            }
        ))
    }

    const formikRef = useFormikRef(
        {...user, name: '', surname: '', phoneNumber: ''},
        validation.addUser(),
        onSubmit,
    );

    const formik = formikRef.current


    useEffect(() => {
        console.log(error)
        if (error && error.messages) {
            if (error.messages.Password) {
                formikRef.current.setErrors({password: error.messages.Password[0]})
            }
            else if (error.messages.Email) {
                formikRef.current.setErrors({email: error.messages.Email[0]})
            }
            else if(error.messages.Details) {
                console.log(error.messages.Details[0])
                formikRef.current.setErrors({details: error.messages.Details[0]})
            }
        }
        return;
    },[error,formikRef])

    return (
        <Box className='modal-form-wrap'>
            <Typography className='form-title' fontSize='xx-large' component="h1" fontWeight='bold' variant="h5">
                Add user
            </Typography>
            <Box className='form' component='form' sx={{mt: 1}} onSubmit={formik.handleSubmit}>

                <TextField
                    className='form-inp'
                    color='warning'
                    margin='normal'
                    id='outlined-basic'
                    label='Email'
                    variant='outlined'
                    type='text'
                    name='email'
                    inputProps={{className : 'input'}}
                    InputLabelProps={{
                        style: {
                            color: 'white'
                        }
                    }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={ (formik.touched.email && Boolean(formik.errors.email))}
                    helperText={formik.errors.email as string}
                />

                <TextField
                    className='form-inp'
                    margin='normal'
                    color='warning'
                    id='outlined-basic'
                    label='Phone'
                    variant='outlined'
                    type='text'
                    name='phoneNumber'
                    inputProps={{style: {color: '#BEB7B7'}}}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={ (formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber))}
                    helperText={formik.errors.phoneNumber as string}
                />


                <Divider className={'divider'} sx={{width: '60%'}} variant="middle" />

                <TextField
                    className='form-inp'
                    color='warning'
                    margin='normal'
                    id='outlined-basic'
                    label='Name'
                    variant='outlined'
                    type='text'
                    name='name'
                    inputProps={{style: {color: '#BEB7B7'}}}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={ (formik.touched.name && Boolean(formik.errors.name))}
                    helperText={formik.errors.name as string}
                />
                <TextField
                    className='form-inp'
                    margin='normal'
                    color='warning'
                    id='outlined-basic'
                    label='Surname'
                    variant='outlined'
                    type='text'
                    name='surname'
                    inputProps={{style: {color: '#BEB7B7'}}}
                    value={formik.values.surname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={ (formik.touched.surname && Boolean(formik.errors.surname))}
                    helperText={formik.errors.surname as string}
                />

                <FormHelperText
                    error={Boolean(formik.errors.details)}
                >
                    {formik.errors.details}
                </FormHelperText>


                <Divider className='divider' sx={{width: '100%'}} variant="middle" />
                <Box sx={{display: 'flex', flexDirection: 'column',gap: 2}}>
                    <Button className='form-btn' variant='contained' color='success' type='submit'>Create</Button>
                    <Button className='form-text-btn' variant='text' onClick={() => setShow(false)} >Decline</Button>
                </Box>
            </Box>
        </Box>
    )
}
