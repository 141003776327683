import {createSlice} from "@reduxjs/toolkit";
import {IAction, IError, IErrorsState, IStoreState} from "../../models";

export const initialState: IErrorsState = {
    signin: null,
    createUser: null,
    sendLetter: null,
    confirmPassword: null,
    userDetails: null,
    updatePassword: null,
    changePassword: null,
    changeEmail: null,
    ui: null,
}

const errorsSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        errorsSignInUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.signin = action.payload;
        },
        errorsCreateUserUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.createUser = action.payload;
        },
        errorsSendLetterUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            console.log(action.payload)
            state.sendLetter = action.payload;
            console.log(state.sendLetter)
        },
        errorsUserDetailsUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.userDetails = action.payload;
        },
        errorsUpdatePasswordUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.updatePassword = action.payload;
        },
        errorsChangePasswordUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.changePassword = action.payload;
        },
        errorsChangeEmailUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.changeEmail = action.payload;
        },
        errorsConfirmPasswordUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.confirmPassword = action.payload;
        },
        errorsUiUpdateAction(state: IErrorsState ,action: IAction<IError | null>) {
            state.ui = action.payload;
        },
        errorsClear(state: IErrorsState) {
            state.signin = null;
            state.createUser = null;
            state.sendLetter = null;
            state.confirmPassword = null;
            state.userDetails = null;
            state.updatePassword = null;
            state.changePassword = null;
            state.changeEmail = null;
            state.ui = null;
        }

    }
});

export const errorsReducer = errorsSlice.reducer;

export const {
    errorsConfirmPasswordUpdateAction,
    errorsSendLetterUpdateAction,
    errorsSignInUpdateAction,
    errorsUiUpdateAction,
    errorsUpdatePasswordUpdateAction,
    errorsChangePasswordUpdateAction,
    errorsUserDetailsUpdateAction,
    errorsChangeEmailUpdateAction,
    errorsCreateUserUpdateAction,

    errorsClear
} = errorsSlice.actions;

export const errorsSignInSelector = (state: IStoreState) => state.errors.signin;
export const errorsCreateUserSelector = (state: IStoreState) => state.errors.createUser;
export const errorsSendLetterSelector = (state: IStoreState) => state.errors.sendLetter;
export const errorsPasswordSelector = (state: IStoreState) => state.errors.confirmPassword;
export const errorsUiSelector = (state: IStoreState) => state.errors.ui;

// export const errorsUserDetailsSelector = (state: IStoreState) => state.errors.userDetails;
// export const errorsUpdatePasswordSelector = (state: IStoreState) => state.errors.updatePassword;
// export const errorsChangePasswordSelector = (state: IStoreState) => state.errors.changePassword;
// export const errorsChangeEmailSelector = (state: IStoreState) => state.errors.changeEmail;
