import { createBrowserRouter } from "react-router-dom";
import {
    RouteBackGuard,
    RouteGuardByToken, RouteGuardByType,
} from "./guardianRoute";
import { HOME, REDIRECT, SIGN_IN} from "./paths";

import { RedirectRoute} from "./redirectRoutes";
import {auth_routes, main_routes} from "./routes";




export const router = createBrowserRouter(
    [
        {
            element: <RouteBackGuard  goTo={HOME}/>,
            children: auth_routes,
        },
        {
            element: <RouteGuardByToken loged={true} goTo={SIGN_IN} />,
            children: [
                {
                    element: <RouteGuardByType type={'Admin'} goTo={SIGN_IN}/>,
                    children: main_routes
                },


            ]
        },
        {
            path: REDIRECT,
            element: <RedirectRoute/>
        },

    ], {
        basename: "/",

    },
);



