import {call, put, select} from "@redux-saga/core/effects";
import {addCurrRoleAction, roleChangeLoadingAction, roleIndexesSelector, roleSelector} from "../../slices/roleSlice";
import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {claimsSelector} from "../../slices/claimsSlice";
import {IError, IRoleData} from "../../../models";
import {getClaims} from "../../../helpers/getClaims";
import {fetchUpdateRole} from "../../../services/RoleService";
import {getRolesSaga} from "./getRolesSaga";
import {uiChangeMessageAction, uiShowModalAction} from "../../slices/uiSlice";

export function* editRoleSaga(): Generator {
    try {
        yield put(roleChangeLoadingAction(true))
        yield put(errorsUiUpdateAction(null));

        let role = yield select(roleSelector);
        let ids = yield select(roleIndexesSelector);
        let claims = yield select(claimsSelector);

        let data = yield  {
            id: ((role as IRoleData).id as string),
            name: (role as IRoleData).name,
            accessType: (role as IRoleData).accessType,
            claims : yield getClaims(claims,(ids as number[]))
        }

        let res = yield call(() => fetchUpdateRole(data as IRoleData));
        if ((res as Object).hasOwnProperty('errorCode')  && (res as IError).errorCode !== 10 ) {
            throw res;
        }
        else {
            yield put(uiShowModalAction(false));
            yield put(addCurrRoleAction({
                id: '',
                name: '',
                accessType: 'Client',
                claims: []
            }))

            yield put(uiChangeMessageAction({
                isShow: true,
                title: 'Edited!',
                desc: `Role "${(data  as any).name}" was edited`,
                role: 'inf'
            }))

            yield call(() => getRolesSaga())
        }
    }

    catch (e) {
        if (!(e as IError).status) {
            yield put(errorsUiUpdateAction((e as IError)));
        }
    }
    finally {
        put(roleChangeLoadingAction(false))
    }
}
