import {Navigate, Outlet} from 'react-router-dom';
import {FC} from "react";
import { useSelector} from "react-redux";
import {tokensAccessSelector, tokensResetReqSelector} from "../store/slices/tokensSlice";
import {adminTypeSelector} from "../store/slices/adminSlice";
import {TUserType} from "../models/UserClaims";
import {signInPasswordSelector} from "../store/slices/signInSlice";

type TProps = {
    goTo: string;
    loged?: boolean,
    type?: TUserType
}

export const RouteGuardByToken: FC<TProps> = ({loged,goTo}) => {
    let token = !!useSelector(tokensAccessSelector);
    console.log(token)
    return  (token ? <Outlet /> : <Navigate to={goTo} />)
};

export const RouteGuardBydPass: FC<TProps> = ({goTo}) => {
    let pass = useSelector(signInPasswordSelector);
    return pass ? <Outlet /> : <Navigate to={goTo} />
};

export const RouteBackGuard: FC<TProps> = ({goTo}) => {
    let isConfirm = useSelector(tokensResetReqSelector);
    let token = !!useSelector(tokensAccessSelector);
    return !isConfirm && token ? <Navigate to={goTo} /> : <Outlet />
};


export const RouteGuardByType: FC<{type: TUserType,goTo: string}> = ({type,goTo}) => {

    let userType = useSelector(adminTypeSelector);
    console.log(userType)
    return type === userType ? <Outlet  /> : <Navigate to={goTo} />
};
