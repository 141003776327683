import {call, put, select} from "@redux-saga/core/effects";
import { roleChangeLoadingAction, roleIndexesSelector, roleSelector} from "../../slices/roleSlice";
import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {IError, IRoleData} from "../../../models";
import {claimsSelector} from "../../slices/claimsSlice";
import {getClaims} from "../../../helpers/getClaims";
import {fetchSendNewRole} from "../../../services/RoleService";
import {getRolesSaga} from "./getRolesSaga";
import {uiChangeMessageAction, uiShowModalAction} from "../../slices/uiSlice";


export function* sendNewRoleSaga(): Generator {
    try {
       yield put(roleChangeLoadingAction(true))
       yield put(errorsUiUpdateAction(null));

        let role = yield select(roleSelector);
        let ids = yield select(roleIndexesSelector);
        let claims = yield select(claimsSelector);
        let data = yield  {
            name: (role as IRoleData).name,
            accessType: (role as IRoleData).accessType,
            claims : yield getClaims(claims,(ids as number[]))
        }

        let res = yield call(() => fetchSendNewRole(data as IRoleData));
        if ((res as Object).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
            throw res;
        }
        else {
            yield call(() => getRolesSaga());
            yield put(uiShowModalAction(false));
            yield put(uiChangeMessageAction({
                isShow: true,
                title: 'Created!',
                desc: `Role ${(data as IRoleData).name} was created!`,
                role: 'inf'
            }));
        }
    }

    catch (e) {
        if (!(e as IError).status) {
            yield put(errorsUiUpdateAction((e as IError)));
        }
    }
    finally {
        put(roleChangeLoadingAction(false))
    }
}
