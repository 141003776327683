import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, {FC, useState} from "react";

type TProps = {
    items: {label: string, callback?: () => void}[]
    iconClassName?: string
}

export const CustomMenu:FC<TProps> = ({items,iconClassName}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAdd = (callback?: () => void) => {
        if (callback) {
            callback()
        }
        handleClose()
    }

    return (<div>

        <IconButton
            className={iconClassName}
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id="long-menu"
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >

            {items.map(el => <MenuItem key={el.label} onClick={() => handleAdd(el.callback)}>{el.label}</MenuItem>)}

        </Menu>
    </div>)
}
