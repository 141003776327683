import {FC, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Divider,
    TextField, ThemeProvider,
    Typography
} from "@mui/material";
import {Copyright, PasswordInput} from "../../index";
import {validation} from "../../../schemas/validation.schemas";
import {useDispatch, useSelector} from "react-redux";
import {
    signInAction,
    signInStoreSelector
} from "../../../store/slices/signInSlice";
import {errorsSignInSelector} from "../../../store/slices/errorSlice";
import {useFormikRef} from "../../../hooks";
import '../styles.scss'
import {theme} from "../../../theme";


export const FormSignIn: FC = () => {
    const signInValues = useSelector(signInStoreSelector);
    const error = useSelector(errorsSignInSelector);
    const [errField, setErrField] = useState('');


    const dispatch = useDispatch();


    const onSubmit = (values: {email: string, password: string,rememberMe: boolean, accessType: 'Admin'}) => {
        dispatch(signInAction(values));
    };

    const formikRef = useFormikRef(
        signInValues,
        validation.signIn(),
        onSubmit,
    );


    useEffect(() => {
        if (error && error.messages) {
            if (error.messages.Password) {
                formikRef.current.setErrors({password: error.messages.Password[0]})
                setErrField('password')
            }
            else if (error.messages.Email) {
                formikRef.current.setErrors({email: error.messages.Email[0]})
                setErrField('email')
            }
            else if(error.messages.ActionError) {
                formikRef.current.setErrors({email: error.messages.ActionError[0]})
                setErrField('email')
            }
        }
        return;
    },[error,formikRef])


    const formik = formikRef.current

    return (
        <ThemeProvider theme={theme}>
            <Box className='form-wrap'>
                <Typography sx={{mb:8}} fontSize='xx-large' component="h1" variant="h5">
                    Admin  Sign in
                </Typography>
                <Box className='form' component='form' onSubmit={formik.handleSubmit}>
                    <TextField
                        className='form-inp'
                        id='outlined-basic'
                        label='Email'
                        inputProps={{style: {color: '#BEB7B7'}}}
                        variant='outlined'
                        type='email'
                        name='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={ (formik.touched.email && Boolean(formik.errors.email)) || errField === 'email'}
                        helperText={formik.errors.email as string}
                    />

                    <PasswordInput
                        className='form-inp'
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={ (formik.touched.password && Boolean(formik.errors.password)) || errField === 'password'}
                        helperText={formik.errors.password as string}
                    />


                    <Divider className='divider' variant="middle" />
                    <Box sx={{display: 'flex', flexDirection: 'column',gap: 2}}>
                        <Button className='form-btn' variant='contained' type='submit' >Sign in</Button>
                    </Box>
                </Box>
                <Copyright />
            </Box>
        </ThemeProvider>

    )
}
export default FormSignIn;
