import {Box, Button, Divider} from "@mui/material";
import {PasswordInput} from "../../password-input";
import {validation} from "../../../schemas/validation.schemas";
import '../styles.scss'
import {useDispatch} from "react-redux";
import {useFormikRef} from "../../../hooks";
import {FC} from "react";
import {PasswordRulesList} from "../../passwordRulesList";
import {updatePasswordConfirmAction} from "../../../store/slices/tokensSlice";

export const FormSetPassword: FC<{pass?: string}> = ({pass}) => {

    let dispatch = useDispatch();


    const onSubmit = (values: {password: string, repPassword: string,newPassword: string}) => {
       dispatch(updatePasswordConfirmAction({password: values.password}))
    }


    const formikRef = useFormikRef(
        {password: pass ? pass : '', repPassword: ''},
        validation.setPassword(),
        onSubmit,
    );

    const formik = formikRef.current


    return (
        <Box className='form-wrap'>

            <Box className='form' component='form' sx={{mt: 1}} onSubmit={formik.handleSubmit}>



                    <PasswordInput
                        className='form-inp'
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={ (formik.touched.newPassword && Boolean(formik.errors.newPassword))}
                        helperText={formik.errors.newPassword as string}
                        name='password'
                        label={'Password'}
                    />

                    <PasswordInput
                        className='form-inp'
                        value={formik.values.repPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={ (formik.touched.repPassword && Boolean(formik.errors.repPassword))}
                        helperText={formik.errors.repPassword as string}
                        label='Confirm password'
                        name="repPassword"
                    />


                <PasswordRulesList />
                <Divider className='divider' sx={{width: '100%'}} variant="middle" />
                <Box sx={{display: 'flex', flexDirection: 'column',gap: 2}}>
                    <Button
                        className='form-btn'
                        variant='contained'
                         type='submit'
                    >Update</Button>
                </Box>
            </Box>
        </Box>
    )
}
