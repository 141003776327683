import {put, call} from "@redux-saga/core/effects";
import {uiChangeLoadingAction} from "../../slices/uiSlice";
import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {AxiosResponse} from "axios";
import {IError, IRoleData,} from "../../../models";
import {fetchGetRoleList} from "../../../services/RoleService";
import {addRolesAction} from "../../slices/roleSlice";

export function* getRolesSaga(): Generator {
    try {
        yield put(uiChangeLoadingAction(true));
        yield put(errorsUiUpdateAction(null));

        let res: AxiosResponse<IRoleData[]> | IError | unknown = yield call(() => fetchGetRoleList());
        if ((res as AxiosResponse).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
            throw res
        }
        else {
            yield  put(addRolesAction((res as IRoleData[])));
        }

    }
    catch (e) {
        console.log(e)
    }
    finally {
        yield put(uiChangeLoadingAction(false));
    }
}
