import {Router} from "@remix-run/router";
import {getContext} from "redux-saga/effects";
import {HOME} from "../../../routes/paths";
import {fetchSetPassword} from "../../../services/AuthService";
import {call, put} from "@redux-saga/core/effects";
import {updateEmailConfirmAction, updateResetConfirmAction, updateTokensAction} from "../../slices/tokensSlice";
import {AxiosResponse} from "axios";
import {IAction, IError, IUpdatePasswordBody} from "../../../models";
import {uiChangeLoadingAction, uiChangeMessageAction} from "../../slices/uiSlice";
import {errorsConfirmPasswordUpdateAction, errorsUiUpdateAction} from "../../slices/errorSlice";
import {signInClearStateAction} from "../../slices/signInSlice";


export function* confirmPasswordSaga (action: IAction<IUpdatePasswordBody>): Generator<{type: String, action?: any}> | Generator{

    try {
        yield put(errorsConfirmPasswordUpdateAction(null));
        yield put(errorsUiUpdateAction(null));
        yield put(uiChangeLoadingAction(true));
        let res: AxiosResponse | IError | unknown  = yield call(() => fetchSetPassword(action.payload));
        if((res as AxiosResponse).status === 204) {

            yield put(updateResetConfirmAction(false));
            yield put(updateEmailConfirmAction(true));
            yield put(updateTokensAction());

            yield put(uiChangeMessageAction({
                isShow: true,
                title: 'Updated!',
                desc: `You successfully created own password in next sign in please use your own password`,
                role: 'inf'
            }))

            const router: Router | unknown = yield (getContext('router'));
            yield (router as Router).navigate(HOME);
            yield put(signInClearStateAction());

        }
        else {
            throw res;
        }
    }
    catch (e) {
        if ((e as IError).status) {
            yield put(errorsConfirmPasswordUpdateAction((e as IError)));
        }
        else {
            yield put(errorsUiUpdateAction( (e as IError)));
        }

    }
    finally {
        yield put(uiChangeLoadingAction(false))
    }
}
