import {createAction, createSlice} from "@reduxjs/toolkit";
import {IAction, IClaimData, IRoleData, IStoreState, IUsersState, IUserState} from "../../models";

const initialState: IUsersState= {
    data: [],

    user: {
        id: '',
        email: '',
        phoneNumber: '',
        userAccess: 'Client',
        roles: null,
        claims: null
    },
};


export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        addUsersAction(state: IUsersState,action:IAction<IUserState[]>) {
            state.data = action.payload;
        },
        addUserAction(state: IUsersState, action: IAction<IUserState>) {
            state.user = action.payload;
        },
        addUserRolesAction(state: IUsersState, action: IAction<IRoleData[]>) {
            if (state.user) {
                state.user.roles = action.payload
            }
        },
        addUserClaimsAction(state: IUsersState, action: IAction<IClaimData[]>) {
            if (state.user) {
                state.user.claims = action.payload
            }
        }
    }

})

export const usersReducer = usersSlice.reducer;

// ACTIONS

export const {
    addUsersAction,
    addUserAction,
    addUserRolesAction,
    addUserClaimsAction,
} = usersSlice.actions;

export const GET_USERS = 'userSlice/getUsersDetailsAction';
export const GET_USER_ROLES = 'userSlice/getUserRolesAction';
export const SEND_USER_ROLES = 'userSlice/sendUserRolesAction';
export const DELETE_USER_ROLE = 'userSlice/deletedUserRolesAction';
export const GET_USER_CLAIMS = 'userSlice/getUserClaimsAction';
export const SEND_NEW_USER = 'userSlice/addNewUserAction';

export const getUsersAction = createAction(GET_USERS);
export const getUserRolesAction = createAction(GET_USER_ROLES,payload => ({payload}));
export const sendUserRolesAction = createAction(SEND_USER_ROLES,payload => ({payload}));
export const deletedUserRolesAction = createAction(DELETE_USER_ROLE,payload => ({payload}));
export const getUserClaimsAction = createAction(GET_USER_CLAIMS,payload => ({payload}));
export const addNewUserAction = createAction(SEND_NEW_USER, payload => ({payload}));



// SELECTOR
export const usersSelector = (state: IStoreState) => state.users.data;
export const userSelector = (state: IStoreState) => state.users.user;
export const userRolesSelector = (state: IStoreState) => state.users.user?.roles;
export const userClaimsSelector = (state: IStoreState) => state.users.user?.claims;

