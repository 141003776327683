import {put, call} from "@redux-saga/core/effects";
import {uiChangeLoadingAction} from "../../slices/uiSlice";
import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {fetchGetUsers} from "../../../services/UsersService";
import {AxiosResponse} from "axios";
import {IError, IUserState} from "../../../models";
import {addUsersAction} from "../../slices/usersSlice";

export function* getUsersSaga(): Generator {
    try {
        yield put(uiChangeLoadingAction(true));
        yield put(errorsUiUpdateAction(null));

        let res: AxiosResponse<IUserState[]> | IError | unknown = yield call(() => fetchGetUsers())
        if ((res as AxiosResponse).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
          throw res;
        }
        else {
            yield  put(addUsersAction((res as IUserState[])));
        }

    }
    catch (e) {
        if (!(e as IError).status) {
            yield put(errorsUiUpdateAction((e as IError)));
        }
    }
    finally {
        yield put(uiChangeLoadingAction(false));

    }
}
