import {FC, useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button, CircularProgress,
    Divider, FormControl, InputLabel, List, ListItem, MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {validation} from "../../../schemas/validation.schemas";
import {useDispatch, useSelector} from "react-redux";
import {useFormikRef} from "../../../hooks";
import {claimsSelector} from "../../../store/slices/claimsSlice";
import {DeepCheckList} from "../../deep-check-list";
import {
    addCurrRoleAction,
    addRoleClaimIdsAction, editRoleAction,
    roleSelector,
    setNewRoleAction
} from "../../../store/slices/roleSlice";
import { FormHelperText } from '@mui/material'
import {IRoleData} from "../../../models";
import {getClaimId} from "../../../helpers";
import '../styles.scss'

type TListProps = {
    id: string,
    callback: (indexes: number[]) => void,
    edit: boolean

}


const AddRoleClaimsList:FC<TListProps> = ({callback,edit}) => {
    let [ids,setIds] = useState<number[]>([]);
    let [editIds, setEditIds] = useState([])
    const claims = useSelector(claimsSelector);
    let currRole = useSelector(roleSelector);

    useEffect(() => {
        callback(ids)
    },[ids,callback]);

    useEffect(() => {
        if(edit && currRole) {
            setEditIds((getClaimId(currRole.claims) as never));
            setIds((getClaimId(currRole.claims) as never));
        }
    },[edit,currRole])


    const setIndex = useCallback((index: number, oper: boolean) => {
        if (!oper) {
            setIds(ids.filter(el => el !== index))
        }
        else if(oper){
            setIds(ids.concat(index))
        }
        return;
    },[ids,setIds])

    return claims ? (
        <List className='form-list'>
            {(claims as Array<any>).map((e: any) => <ListItem key={e.name}><DeepCheckList editIds={edit ? editIds : undefined} edit={edit} setIndex={setIndex} item={e} /></ListItem>)}
        </List>
    ) : <CircularProgress sx={{margin: '0 auto'}} />
}


export const FormRole: FC<{setShow : (show: boolean) => void, edit?: boolean }> = ({setShow,edit}) => {
    const dispatch = useDispatch();
    const currRole = useSelector(roleSelector);
    const [ids, setIds] = useState<number[]>([]);
    const [claimError, setClaimError] = useState(false);



    const onSubmit = (values: IRoleData) => {
        if (ids.length > 0 && !edit) {
            dispatch(addRoleClaimIdsAction(ids))
            dispatch(addCurrRoleAction(values))
            dispatch(setNewRoleAction())
        }
        else if (ids.length > 0 && edit) {
            console.log(ids)
            dispatch(addRoleClaimIdsAction(ids))
            dispatch(addCurrRoleAction(values));
            dispatch(editRoleAction())
        }
        else {
            setClaimError(true)
            formik.setErrors({claims: 'Chooose!!'})
        }
    };

    const handleClaims = (ids: number[]) => {
        setIds(ids);
        setClaimError(false)
    }

    const formikRef = useFormikRef(
        currRole,
        validation.AddRole(),
        onSubmit,
    );

    const formik = formikRef.current

    return (
        <Box className='modal-form-wrap' >
            <Typography fontSize='xx-large' component="h1" fontWeight='bold' variant="h5">
                {edit ? 'Edit' : 'Add new'} role
            </Typography>
            <Box className='form' component='form' sx={{mt: 1}} onSubmit={formik.handleSubmit}>

                <TextField
                    className='form-inp'
                    margin='normal'
                    id='outlined-basic'
                    label='Name'
                    variant='outlined'
                    type='text'
                    name='name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={ (formik.touched.name && Boolean(formik.errors.name))}
                    helperText={formik.errors.name as string}
                />

                <FormControl
                    className='form-inp'

                >
                    <InputLabel>Access Type</InputLabel>

                    <Select
                        disabled={edit}
                        id="accessType"
                        name='accessType'
                        value={formik.values.accessType}
                        label="Access type"
                        onChange={formik.handleChange}
                    >
                        <MenuItem value={formik.values.accessType}>
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={'Admin'}>Admin</MenuItem>
                        <MenuItem value={'Client'}>Client</MenuItem>
                        <MenuItem value={'Merchant'}>Merchant</MenuItem>

                    </Select>
                </FormControl>


                <FormControl  sx={{paddingTop: '25px'}}>
                    <Typography variant={'h6'} fontWeight='bold'>
                        Accesses:
                    </Typography>
                    <AddRoleClaimsList
                        edit={edit ? edit : false}
                        id={'claims'}
                        callback={handleClaims}
                    />
                </FormControl>
                <FormHelperText
                    className='help-text'
                    error={claimError}
                >
                    You can't create role without accesses
                </FormHelperText>
                <Divider className='divider' sx={{width:"100%"}} variant="middle" />
                <Box sx={{display: 'flex', flexDirection: 'column',gap: 2}}>
                    <Button className='form-btn' variant='contained' color='success' type='submit'>{edit ? "Update" : "Create"}</Button>
                    <Button className='form-text-btn' variant='text' onClick={() => setShow(false)} >Decline</Button>
                </Box>
            </Box>
        </Box>
    )
}
