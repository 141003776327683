import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { getRolesAction, rolesSelector } from "../../../store/slices/roleSlice";
import {
    Box, Button, Checkbox, FormControlLabel, FormGroup, List, ListItem, Typography,
} from "@mui/material";
import {sendUserRolesAction, userSelector} from "../../../store/slices/usersSlice";
import '../styles.scss'

export const AddRoleToUserForm: FC<{setShow : (show: boolean) => void}> = ({setShow}) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const userRolesIds = useMemo(() => (user.roles ? user.roles?.map(el => el.id) : []),[user.roles]);
    let roles = useSelector(rolesSelector);
    const [ids, setIds] = useState<string[]>([]);


    let filteredRoles = useMemo(() => (roles ? roles.filter(el =>
            !(userRolesIds as string[]).includes((el.id as string)) && el.accessType === user.userAccess)
        : []),[roles,user.userAccess,userRolesIds]);



    const addId = useCallback((e: any,id:string) => {
        if (e.target.checked) {
            setIds(ids.concat(id))
        }
        else if (!e.target.checked){
            setIds(ids.filter(item => item !== id))
        }
    },[ids])

    const sendId = () => {
        dispatch(sendUserRolesAction({userId: user.id,roleIds: ids}));
        setShow(false)
    }

    useEffect(() => {
        if (!roles || roles.length < 1) {
            dispatch(getRolesAction());
        }
        return;
    },[roles,dispatch])

    return (
        <Box className='modal-form-wrap'>
            <Box className='form' >
                <Typography sx={{mb:2}} variant={'h5'}>
                    Add new role <br/>
                    to user {user.email}
                </Typography>
                <Box>
                    {

                        filteredRoles.length > 0 ?
                            <FormGroup className='form-roles-list'>
                                {
                                    filteredRoles.map(el => (
                                            <FormControlLabel key={el.id} label={el.name} control={
                                                <Checkbox
                                                    sx={{color: ' #BEB7B7'}}
                                                    color='warning'
                                                    onChange={(e => addId(e,(el.id as string)))}
                                                />} />
                                        )
                                    )}
                            </FormGroup>
                            :
                            <Box >
                                <Typography>
                                    Ooops Sorry:(
                                </Typography>
                                <List className='forb-msg--list' >
                                    <ListItem>
                                        1) You already have all roles with access type "{user.userAccess}"
                                    </ListItem>
                                    <ListItem>
                                        2) We  haven't roles with access type "{user.userAccess}"
                                    </ListItem>
                                </List>
                            </Box>
                    }
                </Box>


                <Box sx={{display: 'flex', flexDirection: 'column',gap: 2}}>
                    {filteredRoles.length > 0  ?
                        <Button disabled={ids.length < 1} onClick={sendId} className='form-btn' variant='contained' color='success' type='submit'>Add</Button>
                        :
                        null
                    }
                    <Button className='form-text-btn' variant='text' onClick={() => setShow(false)} >Decline</Button>
                </Box>
            </Box>
        </Box>
    )
}
