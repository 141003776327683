import {AxiosError, AxiosResponse} from "axios";
import {IClaimData, IError, IRoleData} from "../models";
import {transformError} from "../helpers";
import $api from "../http";
import {checkResponse} from "./checkResponse";


const rolePath: string = '/Role';
const claimsPath: string = '/Role/claims';



export const fetchDeleteRole = (id:string) => {
    return $api.delete(`${rolePath}?id=${id}`).then(res => checkResponse(res)).catch((e: AxiosError) => transformError(e))
}

export const fetchUpdateRole = (data: IRoleData) => {
    return  $api.put(rolePath,data).then(res => checkResponse(res)).catch((e: AxiosError) => transformError(e))
}

export const fetchSendNewRole = (data: IRoleData) => {
    return  $api.post(rolePath,data).then(res => checkResponse(res)).catch((e: AxiosError) => transformError(e))
}


export const fetchGetRoleList = ():Promise<AxiosResponse<IRoleData[]> | IError> => {
    return $api.get(rolePath).then(res => checkResponse(res)).catch((e: AxiosError) => transformError(e))
};

export const fetchGetRoleClaimsList = ():Promise<AxiosResponse<IClaimData[]> | IError> => {
    return $api.get(claimsPath).then(res => checkResponse(res)).catch((e: AxiosError) => transformError(e));
};

