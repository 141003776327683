import {IAction, IError, IUserState} from "../../../models";
import {errorsCreateUserUpdateAction} from "../../slices/errorSlice";
import {call, put} from "@redux-saga/core/effects";
import {fetchSendNewUser} from "../../../services/UsersService";
import {uiChangeMessageAction, uiShowModalAction} from "../../slices/uiSlice";
import {getUsersAction} from "../../slices/usersSlice";

export function* addNewUserSaga (action: IAction<IUserState>): Generator {
    try {
        yield put(errorsCreateUserUpdateAction(null));

        let res = yield call(() => fetchSendNewUser(action.payload));
        if ((res as Object).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10 ) {
            throw res;
        }
        else {
            yield put(getUsersAction());
            yield put(uiShowModalAction(false));
            yield put(uiChangeMessageAction({
                isShow: true,
                title: 'Added!',
                desc: `New user was added!`,
                role: 'inf'
            }))
        }

    }
    catch (e) {
        if ((e as IError).status) {
            yield put(errorsCreateUserUpdateAction(e as IError));
        }
    }
}
