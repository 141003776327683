import {Box, Checkbox, FormControlLabel} from "@mui/material";
import {FC, useEffect, useState} from "react";



type TProps = {
    item: any,
    setIndex: (index: number,oper: boolean) => void,
    edit?: boolean,
    editIds?: number[]
    padd? : number
}

export const DeepCheckList: FC<TProps> = ({item, setIndex,edit,editIds,padd=0}) => {
    let [checked, setChecked] = useState(false);
    let [point] = useState(item);



    useEffect(() => {
        if (edit && editIds) {
            if (editIds.includes((point.id) as never)) {
                setChecked(true)
            }
        }
        return;
    },[edit,editIds,point.id])


    const handleCheck = () => {
        setChecked(!checked)
        setIndex(point.id, !checked)
    }




    return (
        <Box sx={{width: '100%'}}>
            <FormControlLabel
                sx={{margin: 0}}
                label={item.name}
                control={<Checkbox
                color='warning'
                sx={{
                    paddingLeft: padd ? `${padd + 15}px` : 0, color: ' #BEB7B7'}}
                onChange={handleCheck}
                checked={checked}  />
            }  />
            {checked && Array.isArray(item.childClaims) ?
                item.childClaims.map((e: any,i: number) => <DeepCheckList
                    padd={padd + 15}
                    editIds={editIds}
                    edit={edit}
                    key={item.name + i}
                    setIndex={setIndex}
                    item={e} />)
                : null
            }
        </Box >
    )
}
