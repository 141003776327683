import {IAction, IError} from "../../../models";
import {call, put} from "@redux-saga/core/effects";
import {errorsUiUpdateAction} from "../../slices/errorSlice";
import {fetchSendRole} from "../../../services/UsersService";
import {getUserClaimsAction, getUserRolesAction} from "../../slices/usersSlice";
import {uiChangeMessageAction, uiShowModalAction} from "../../slices/uiSlice";

export function* addRoleToUserSaga (action: IAction<{userId: string, roleIds: string[]}>):Generator {
    try {
        yield put(errorsUiUpdateAction(null))
        let res = yield call(() =>
            action.payload.roleIds.map((id) =>
                fetchSendRole({userId: action.payload.userId, roleId: id})
            )[0]
        );
        if ((res as Object).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
           throw res;
        }
        else {
            yield put(getUserRolesAction(action.payload.userId))
            yield put(getUserClaimsAction(action.payload.userId))
            yield put(uiShowModalAction(false));
            yield put(uiChangeMessageAction({
                isShow: true,
                title: 'Added!',
                desc: `Roles was added!`,
                role: 'inf'
            }))
        }
    }
    catch (e) {
        yield put(errorsUiUpdateAction(e as IError))

    }
}
