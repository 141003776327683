import {createAction, createSlice} from "@reduxjs/toolkit";
import {IAction, IStoreState, IRoleData, IRolesState} from "../../models";

const initialState: IRolesState = {
    loading: false,
    data: null,
    currRole: {
        name: '',
        accessType: 'Client',
        claims: [],
    },
    claim_ids: [],
    del_role: {
        id: '',
        name: ''
    }
};


export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        addRolesAction(state: IRolesState,action:IAction<IRoleData[] | []>) {
            state.data = action.payload as [];
        },
        addCurrRoleAction(state: IRolesState,action:IAction<IRoleData>) {
            state.currRole = action.payload;
        },
        addRoleToListAction(state: IRolesState,action:IAction<IRoleData>) {
            state.data = state.data ? state.data.concat(action.payload) : [].concat((action.payload as never));
        },
        addRoleClaimIdsAction(state: IRolesState,action:IAction<number[]>) {
            state.claim_ids = action.payload;

        },
        roleChangeLoadingAction(state: IRolesState,action:IAction<boolean>) {
            state.loading = action.payload;
        },
        roleAddDelIdAction(state: IRolesState,action:IAction<{id: string, name: string}>) {
            state.del_role = action.payload;
        },
    }

})

export const rolesReducer = rolesSlice.reducer;

// ACTIONS

export const {
    addRolesAction,
    addCurrRoleAction,
    addRoleToListAction,
    addRoleClaimIdsAction,
    roleChangeLoadingAction,
    roleAddDelIdAction
} = rolesSlice.actions;

export const GET_ROLES = 'rolesSlice/getRolesAction';
export const SET_NEW_ROLE = 'rolesSlice/setNewRoleAction';
export const EDIT_ROLE = 'rolesSlice/editRoleAction';
export const DELETE_ROLE = 'rolesSlice/deleteRoleAction';

export const getRolesAction = createAction(GET_ROLES);
export const setNewRoleAction = createAction(SET_NEW_ROLE);
export const editRoleAction = createAction(EDIT_ROLE);
export const deleteRoleAction = createAction(DELETE_ROLE);



// SELECTOR
export const rolesSelector = (state: IStoreState) => state.roles.data;
export const roleSelector = (state: IStoreState) => state.roles.currRole;
export const roleIndexesSelector = (state: IStoreState) => state.roles.claim_ids;
export const roleLoadingsSelector = (state: IStoreState) => state.roles.loading;
export const roleDelSelector = (state: IStoreState) => state.roles.del_role;

