import {AxiosError, AxiosResponse} from "axios";
import {IError, IUserState} from "../models";
import $api from "../http";
import {transformError} from "../helpers";
import {IUserDetailsBody} from "../models";
import {checkResponse} from "./checkResponse";

const usersPath: string = `/Admin/users`;
const userPath: string = `/Admin/user`;
const userRolesPath: string = `/Role/user`;
const userClaimsPath: string = `/Role/claims/user`;

export const fetchGetUsers = (): Promise<AxiosResponse<IUserDetailsBody> | IError> => {
    return  $api.get(usersPath).then(res => checkResponse(res)).catch((e:AxiosError) => transformError(e));
};

export const fetchSendNewUser = (data: IUserState): Promise<any>  => {
    return  $api.post(userPath,data).then(res => checkResponse(res)).catch((e:AxiosError) => transformError(e));
};

export const fetchSendRole = (data: {userId: string, roleId:string}): Promise<any> => {
    return  $api.post(userRolesPath,data).then(res => checkResponse(res)).catch((e:AxiosError) => transformError(e));
};
export const fetchDeleteRole = (data: {userId: string, roleId:string}): Promise<any> => {
    return  $api.delete(userRolesPath, {
        headers: {"Content-Type": 'application/json'},
        data: data}).then(res => checkResponse(res)).catch((e:AxiosError) => transformError(e));
}

export const fetchGetUserRoles = (id: string): Promise<AxiosResponse<IUserDetailsBody> | IError> => {
    return  $api.get(`${userRolesPath}?id=${id}`).then(res => checkResponse(res)).catch((e:AxiosError) => transformError(e));
}

export const fetchGetUserClaims = (id: string): Promise<AxiosResponse<IUserDetailsBody> | IError> => {
    return  $api.get(`${userClaimsPath}?id=${id}`).then(res => checkResponse(res)).catch((e:AxiosError) => transformError(e));
}


