import {AxiosResponse} from "axios";

export const checkResponse = (res: AxiosResponse) => {
    if (res && !(res as Object).hasOwnProperty('code')) {
        return res.data ?  res.data : res;
    }
    else {
        throw res
    }
}
