export const getClaimId = (arr: any,res: number[] | [] = []) => {
    if (arr.length > 0) {
        arr.forEach((el: any) => {
            res.push((el.id as never))
            if (el.childClaims && el.childClaims.length > 0) {
                return getClaimId(el.childClaims,res)
            }
        })
        return res;
    }
}
