
import { call, put, select} from '@redux-saga/core/effects'
import {
    tokensRefreshSelector, writeTokensAction
} from "../../slices/tokensSlice";
import {fetchRefreshToken, } from "../../../services/RefreshService";
import {errorsClear} from "../../slices/errorSlice";


export function* updateTokensSaga():Generator<{type:String,payload?: any}> | Generator<any> {

    try {
        let refToken = yield select(tokensRefreshSelector);
        let resp = yield call(() => fetchRefreshToken(refToken as string));

        if (!(resp as Object).hasOwnProperty('errorCode')) {
            yield put(writeTokensAction(resp))
            yield put(errorsClear());
        }
    }
    catch (e) {
        console.log(e)
    }
}
