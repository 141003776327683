import {FC, ReactNode, useEffect, useState} from "react";
import {Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {IClaimData, IRoleData} from "../../models";

type TProps = {
    row: IClaimData & IRoleData,
    arrField?: 'childClaims' | 'claims',
    headers: string[]
}


export const DeepRow: FC<TProps> = ({row,arrField, headers}) =>  {
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState(row);
    const [active, setActive] = useState(false);
    const [subHeaders, setSubHeaders] = useState<string[] | []>([]);


    useEffect(() => {
        if (arrField && row[arrField] !== null) {
            // @ts-ignore
            setSubHeaders((Object.keys((row[arrField][0])) as []))
        }
        return;
    },[headers,arrField,row])


    useEffect(() => {
        setRowData(row);
        if (arrField) {
            if (rowData[arrField] && rowData[arrField] !== null) {
                setActive(true)
            }
            else {
                setActive(false)
            }
        }
        return;
    },[row,arrField,rowData])

    return (
        <>
            <TableRow onClick={() => setOpen(!open)} sx={{backgroundColor: active ? '#ECF6F7' : '#ffff'}} >
                {headers.map((el) => (
                    <TableCell
                        key={el}
                        align="left">
                        {
                            // @ts-ignore
                            (Array.isArray(rowData[el as keyof row]) === true ? (rowData[el] as Array<any>).length : rowData[el] as ReactNode)
                        }
                </TableCell>
                ))
                }
            </TableRow>

            {
                arrField && row[arrField] !== null ?

                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                {subHeaders.map(el => (
                                                    <TableCell
                                                    key={el}
                                                    align="left"
                                                    style={{
                                                        backgroundColor: '#5b5b5b',
                                                        color: '#ffff',
                                                        textTransform: 'uppercase',
                                                        fontSize: '12px'
                                                    }}
                                                    >
                                                        {el}
                                                    </TableCell>))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {

                                                (rowData[arrField] as Array<IClaimData>).map((claim: any) => (
                                                claim.hasOwnProperty('claims') || claim.hasOwnProperty('childClaims') ? <DeepRow headers={subHeaders} arrField={'childClaims'} key={claim.id} row={claim} />
                                                    :
                                                    <TableRow key={claim.id}>
                                                        {subHeaders.map((el,i) => (<TableCell key={el + i} align="left">{claim[el]}</TableCell>))}
                                                    </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    :
                    null
            }

        </>
    );
}
