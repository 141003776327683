import {createAction, createSlice} from "@reduxjs/toolkit";
import {IAction, IStoreState, ITokenState} from "../../models";

const initialState: ITokenState = {
    refreshToken: '',
    accessToken: '',
    expire: 0,
    logTime: 0,
    email_confirmed: false,
    password_reset_required: false,
}

export const tokensSlice = createSlice({
    name: 'tokens',
    initialState,
    reducers: {
        addAccessTokenAction(state:ITokenState,action: IAction<string>) {
            state.accessToken = action.payload;
        },
        addRefreshTokenAction(state:ITokenState,action:IAction<string>) {
            state.refreshToken = action.payload;
        },
        addExpireAction(state:ITokenState,action:IAction<number>) {
            state.expire = action.payload;

        },
        addlogTimeAction(state:ITokenState,action:IAction<number>) {
            state.logTime = action.payload;
        },
        updateEmailConfirmAction(state:ITokenState,action:IAction<boolean>) {
            state.email_confirmed = action.payload;
        },
        updateResetConfirmAction(state:ITokenState,action:IAction<boolean>) {
            state.password_reset_required = action.payload;
        },
        clearTokensAction(state:ITokenState) {
            state.expire = 0;
            state.accessToken = '';
            state.refreshToken = '';
            state.email_confirmed = false;
        }

    }

})

export const tokensReducer = tokensSlice.reducer;

// ACTIONS
export const WRITE_TOKENS: string = 'tokensSlice/writeTokensAction';
export const writeTokensAction = createAction(WRITE_TOKENS, payload => ({payload}));

export const UPDATE_TOKENS: string = 'tokensSlice/updateTokens';
export const updateTokensAction = createAction(UPDATE_TOKENS);


export const UPDATE_CONFIRM: string = 'tokensSlice/updateConfirm';
export const updateConfirmAction = createAction(UPDATE_CONFIRM,payload => ({payload}));

export const UPDATE_PASS_CONFIRM: string = 'tokensSlice/updatePasswordConfirmAction';
export const updatePasswordConfirmAction = createAction(UPDATE_PASS_CONFIRM,payload => ({payload}));

export const LOGOUT: string = 'tokensSlice/logout';
export const logoutAction = createAction(LOGOUT,);

export const {
    addAccessTokenAction,
    addRefreshTokenAction,
    addExpireAction,
    updateEmailConfirmAction,
    clearTokensAction,
    addlogTimeAction,
    updateResetConfirmAction,
} = tokensSlice.actions;

// SELECTOR

export const tokensAccessSelector = (state: IStoreState) => state.tokens.accessToken;
export const tokensRefreshSelector = (state: IStoreState) => state.tokens.refreshToken;
export const tokensExpireSelector = (state: IStoreState) => state.tokens.expire;
export const tokensLogedTimeSelector = (state: IStoreState) => state.tokens.logTime;
export const tokensEmailIsConfirmedSelector = (state: IStoreState) => state.tokens.email_confirmed;
export const tokensResetReqSelector = (state: IStoreState) => state.tokens.password_reset_required;
export const tokensSelector = (state: IStoreState) => state.tokens;
