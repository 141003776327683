
import { call, put} from '@redux-saga/core/effects'
import {
    addAccessTokenAction,addRefreshTokenAction,
} from "../../slices/tokensSlice";
import {IAction, IError, ISignInResponse} from "../../../models";
import {decodeJwtSaga} from "./decodeJwtSaga";
import {errorsUiUpdateAction} from "../../slices/errorSlice";


export function* writeTokensSaga(action: IAction<ISignInResponse>):Generator | Generator<any> {

    try {
        let response = action.payload;
        yield put(errorsUiUpdateAction(null))

        if (!(response as Object).hasOwnProperty('errorCode')) {
            yield put(addRefreshTokenAction((response as ISignInResponse).refreshToken));
            yield put(addAccessTokenAction((response as ISignInResponse).accessToken));
            yield call(() => decodeJwtSaga((response as ISignInResponse).userClaims));
            return true;
        }
        else {
            throw response;
        }
    }
    catch (e) {
       yield put(errorsUiUpdateAction(e as IError))

    }
    finally {
    }
}
