export const getClaims = (arr: any,indexes: number[]) => {


    let choose = (arr: any) => {
        return arr.map((el: any) => {
            if (indexes.includes(el.id)) {
                if (el.childClaims || Array.isArray(el.childClaims)) {
                    // @ts-ignore
                    el = {
                        id: el.id,
                        name: el.name,
                        childClaims : choose(el.childClaims),
                    }
                }
            }
            else if (!indexes.includes(el.id)) {
                el =  null;
            }
            return el;
        })
    };
    let clear = (arr: any) => {
        if (arr && arr.length > 0) {
            // eslint-disable-next-line
            return  arr.filter((el: any) => {
                if (el && Array.isArray(el.childClaims)){
                    if (el.childClaims.every((el: any) => el == null)) {
                        el.childClaims = null;
                    }
                    else {
                        el.childClaims = clear(el.childClaims);
                    }
                }
                if (el !== null) {
                    return el;
                }
            })
        }
        return null
    };
    return clear(choose(arr))
}
